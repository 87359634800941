@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');


.owner {
  .action-dropdown {
    padding: 0;
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
    &::after {
      display: none;
    }
  }
}

body{
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
}

body .cover-sets{
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
body .btn.btn-primary.btn-inverted{
  font-weight: 500;
  font-size: 24px;
  background: transparent;
  border-radius: 4px;
  padding: 15px 24px;
  color: white;
  border: 1px solid white !important;
  transition: 0.3s all ease-in-out;
  display: inline-block;
}
body .btn.btn-primary.btn-inverted:hover{
  color:#011b27;
  background-color: white;
}

body footer.d-flex{
  display: none !important;
}
body{
  background-color: #001c27;
}
body .layout{
  background:transparent;
}
body .home .login-container .btn{
  min-width: unset;
}
body .btn.btn-primary{
  padding: 16px 24px;
  background: #8E0D92;
  border-radius: 4px;
  font-weight: 500;
  font-size: 24px;
  color: #FFFFFF;
  margin-left: 8px !important;
  margin-right: 8px !important;
  transition: 0.3s  all ease-in-out;
  border: 1px solid #8E0D92 !important;
}
body .btn.btn-primary:hover{
  border: 1px solid #fff !important;
  background: transparent;
  color: white;
}
body .home .login-container{
  max-width: 1200px;
  width: 100%;

}
body .home .login-container .card-body{
  padding: 120px 0 !important;
}
p{
  font-size: 28px;
  color: #fff;
  line-height: 1.15;
  font-weight: 400;
}
p.dark-p{
  color: #A9C2CF;
}

h4{
  font-weight: bold;
  font-size: 68px;
  line-height: 1.20;
  letter-spacing: 0.8px;
  color: #fff;
}
h4 span{
  color: #8E0D92;
}

body .home .login-container .logo {
  height: 5.5rem;
  margin-bottom: 20px !important;
}

.statcard .icon{
  display: none;
}
.statcard .opacity-6{
  opacity: 1;
  font-size: 12px;
  color: #001C27;
}
.statcard p.h5{
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #001C27;
  padding-top: 4px;
}
.statcard small.opacity-5{
  display: none;
}
body .statcard{
  background: white !important;
}
.dashboard .state p.h4{
  font-weight: normal;
  font-size: 17px;
  letter-spacing: -0.4px;
  color: #A9C2CF;
}
.dashboard .state div.mb-3{
  font-weight: 600;
  font-size: 17px;
  letter-spacing: -0.4px;
  color: #FFFFFF;
  padding-top: 15px;
}
.dashboard p.h6{
  font-weight: 600;
  font-size: 17px;
  letter-spacing: -0.4px;
  color: #FFFFFF;
}
.bottom-dashboard.card{
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.dashboard .header p,
.dashboard .header span{
  font-weight: 600;
  font-size: 17px !important;
  letter-spacing: -0.4px;
  opacity: 1;

}
.dashboard .header p{
  color: #FFFFFF !important;
}
.dashboard .header span{
  color: #A9C2CF;
  font-weight: normal;
}
.navbar span.h5{
  display: none;
}

body .navbar svg.logo {
  height: 4rem;
}


@media screen and (min-width: 1200px){
  body .home-container{
    display: flex;
    align-items: center;
    flex: 1 1 auto ;
  }
}


@media screen and (max-width: 992px){
  h4{
    font-size: 54px;
  }
  body .home .login-container .card-body {
    padding: 50px  !important;
  }
  body .home .login-container .logo {
    height: 4.5rem;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 768px){
  h4{
    font-size: 34px;
  }
  body .home .login-container .card-body {
    padding: 25px  !important;
  }
  body .navbar svg.logo {
    height: 2.2rem;
  }

}
@media screen and (max-width: 576px){
  body .home .login-container .logo {
    height: 2.5rem;
    margin-bottom: 20px !important;
  }
  body .btn.btn-primary.btn-inverted {
    font-size: 16px;
    padding: 10px 21px;
  }
  
}
@media screen and (max-width: 400px){
  body .navbar svg.logo {
    height: 1.4rem;
  }
}